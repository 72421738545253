import PropTypes from 'prop-types';
import XLSX from 'xlsx';
import { Button } from '@mui/material';

function ExportCamData({ data }) {
  const columnsTitle = [
    'ID OPERACION',
    'FECHA',
    'DNI',
    'ENDPOINT',
    'EMPRESA',
    'CUIL',
    'SEXO',
    'NOMBRES',
    'EJEMPLAR',
    'APELLIDOS',
    'DOMICILIO',
    'DNI NUMERO',
    'EXPEDIENTE',
    'DISPOSICION',
    'DNI NUMERO2',
    'CODIGO DORSO',
    'NACIONALIDAD',
    'FECHA EMISION',
    'FECHA INGRESO',
    'NUMERO TRAMITE',
    'FECHA NACIMIENTO',
    'FECHA RADICACION',
    'LUGAR NACIMIENTO',
    'CATEGORIA INGRESO',
    'FECHA VENCIMIENTO',
  ];
  const excelData = [columnsTitle, ...data];

  const exportFile = () => {
    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Onboardings');
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'ReporteOnboardings.xlsx');
  };

  return (
    <Button
      variant="outlined"
      size="small"
      color="success"
      disabled={!data.length}
      onClick={exportFile}
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        mb: '8px',
        width: '120px',
        p: '5px',
      }}
    >
      <img alt="download_icon" src="/static/images/xlsx.png" width={24} />
      EXPORTAR
    </Button>
  );
}

ExportCamData.propTypes = {
  data: PropTypes.any,
  user: PropTypes.string,
};

export default ExportCamData;
