
import { Box, SwipeableDrawer } from '@mui/material';
import DrawerHeader from './DrawerHeader';
import ContentFotos from './ContentFotos';


export default function DrawerFotos({ open, control, idOp, dni, endpoint , idConjuntoImagenes}) {

  return  (
    <SwipeableDrawer onOpen={control()} onClose={control()} anchor="right" open={open}>
      <Box
        minWidth={800}
        sx={{ scrollbarGutter: 'stable', position: 'relative' }}
        role="presentation"
      >
        <DrawerHeader idOp={idOp} dni={dni} endpoint={endpoint} closeDrawer={control} />
        <ContentFotos idConjuntoImagenes={idConjuntoImagenes} />
      </Box>
    </SwipeableDrawer>
  );
}

